


































































































































import { Component, Mixins, Ref, Watch } from "vue-property-decorator";
import { CalendarDispEvent } from "#/components/calendar/common";
import { ScheduleSearchCondition } from "@/components/schedule/common";
import { OfficeStaffChoice } from "#/model/staff";
import { COLLECTION_SCHEDULE_SEARCH } from "@/const/envFireStore";
import { VForm, Choice } from "@/types";
import { CalendarEvent } from "vuetify";
import * as appDate from "#/utility/appDate";
import UtilMixin from "@/mixins/utilMixin";
import AxiosMixin from "@/mixins/axiosMixin";
import FireStoreMixin from "@/mixins/firestoreMixin";
import CalendarFocusChange from "#/components/calendar/CalendarFocusChange.vue";
import CalendarTitleBeforeAfter from "#/components/calendar/CalendarTitleBeforeAfter.vue";
import NowButton from "#/components/calendar/NowButton.vue";
import ScheduleManageSearchPanel from "@/components/schedule/ScheduleManageSearchPanel.vue";
import ScheduleManageDisplaySearch from "@/components/schedule/ScheduleManageDisplaySearch.vue";
import ScheduleManageOfficeSelect from "@/components/schedule/ScheduleManageOfficeSelect.vue";
import ScheduleManageSidebar from "@/components/schedule/ScheduleManageSidebar.vue";
import ScheduleManageTitle from "@/components/schedule/ScheduleManageTitle.vue";
import IbowCalendar from "@/components/common_ibow/calendar/IbowCalendar.vue";
import { EventType } from "#/model/schedule/calendarType";

@Component({
  components: {
    CalendarFocusChange,
    CalendarTitleBeforeAfter,
    NowButton,
    ScheduleManageSearchPanel,
    ScheduleManageDisplaySearch,
    ScheduleManageOfficeSelect,
    ScheduleManageSidebar,
    ScheduleManageTitle,
    IbowCalendar
  }
})
export default class ScheduleManage extends Mixins(
  UtilMixin,
  AxiosMixin,
  FireStoreMixin
) {
  /** フォームエリア */
  @Ref("form") private readonly form!: VForm;

  /** カレンダーコンポーネント */
  @Ref("calendar") private readonly calendar!: IbowCalendar;

  /** 検索パネルコンポーネント */
  @Ref("searchPanel")
  private readonly refSearchPanel!: ScheduleManageSearchPanel;

  /** 検索条件のWatch */
  @Watch("searchCondition", { deep: true }) private changeCondition() {
    this.setSearchCondition();
  }

  /** 検索パネルの開閉状態 */
  private searchPanel = false;

  /** 事業所一覧 */
  private offices: Choice[] = [];

  /** 利用者一覧 */
  private patients: Choice[] = [];

  /** グループ一覧 */
  private groups: Choice[] = [];

  /** 職員一覧 */
  private staffs: OfficeStaffChoice[] = [];

  /** シフト一覧 */
  private shifts: Choice[] = [];

  /** カレンダー 選択日 */
  private focus = this.dateToStr(new Date(), "yyyy-MM-dd");

  private eventType = EventType;

  /** イベント */
  private allEvents: CalendarDispEvent[] = [];
  /** イベント（職員別） */
  private allStaffEvents: CalendarDispEvent[] = [];
  /** イベント */
  private get AllEvents(): CalendarDispEvent[] {
    if (this.DisplayTypeStaff) {
      return this.allStaffEvents;
    } else {
      return this.allEvents;
    }
  }
  /** イベント */
  private set AllEvents(events: CalendarDispEvent[]) {
    if (this.DisplayTypeStaff) {
      this.allStaffEvents = events;
    } else {
      this.allEvents = events;
    }
  }

  /** イベントの年月 */
  private eventYearmonth = "";
  /** イベントの年月（職員別） */
  private eventStaffYearmonth = "";
  /** イベントの年月 */
  private get EventYearmonth(): string {
    if (this.DisplayTypeStaff) {
      return this.eventStaffYearmonth;
    } else {
      return this.eventYearmonth;
    }
  }
  /** イベントの年月 */
  private set EventYearmonth(yearmonth: string) {
    if (this.DisplayTypeStaff) {
      this.eventStaffYearmonth = yearmonth;
    } else {
      this.eventYearmonth = yearmonth;
    }
  }

  /**  iBow KINTAIで取り込んだシフトの色ID */
  private kintaiLinkShiftColorId = "#42BA96";

  /** 検索条件の初期化 */
  private DefaultSearchCond = (): ScheduleSearchCondition => ({
    office_id: 0,
    patient_ids: [], // 検索条件 利用者
    group_ids: [], // 検索条件 グループ
    group_operator: 1, // 検索条件 グループ（AND/OR）
    staff_ids: [], // 検索条件 職員
    shifts: [], // 検索条件 シフト
    layer_display: {
      // レイヤー
      calendar: 1,
      visit: 1,
      visit_no_staff: 1,
      shift: 1
    },
    type: "week",
    data: [] // 検索条件（こっち使う）
  });
  /** 検索条件 */
  private searchCondition = this.DefaultSearchCond();
  /** カレンダー上の検索条件（編集中の内容を含まない） */
  private dispSearch = this.DefaultSearchCond();

  /** 職員別表示フラグ */
  private get DisplayTypeStaff(): boolean {
    return this.searchCondition.type === "category";
  }

  /** 全ての時間帯を表示するかどうか */
  private isOpenOffHours = false;

  /** 日表示の開始時間 */
  private get FirstInterval(): number {
    if (!this.DisplayTypeStaff) {
      return 0;
    }
    if (this.isOpenOffHours) {
      return 0;
    } else {
      return 8;
    }
  }

  /** 日表示の時間回数 */
  private get IntervalCount(): number {
    if (!this.DisplayTypeStaff) {
      return 24;
    }
    if (this.isOpenOffHours) {
      return 24;
    } else {
      return 12;
    }
  }

  /** 職員別表示用全スタッフ */
  private allStaff: number[] = [];

  /** 職員別表示用全利用者 */
  private allPatient: number[] = [];

  /** 絞り込み後のイベント */
  private get FilterEvents(): CalendarDispEvent[] {
    const filterEvents: CalendarDispEvent[] = [];

    //絞り込みができるようにする
    for (const event of this.AllEvents) {
      if (
        (event.type === EventType.VisitSchedule ||
          event.type === EventType.VisitScheduleAIRoute) &&
        this.searchCondition.layer_display.visit
      ) {
        filterEvents.push(event);
      } else if (
        (event.type === EventType.Shift ||
          event.type === EventType.AttendanceShift) &&
        this.searchCondition.layer_display.shift
      ) {
        event.color = this.kintaiLinkShiftColorId;
        filterEvents.push(event);
      } else if (
        (event.type === EventType.VisitScheduleNoStaff ||
          event.type === EventType.VisitScheduleAIRouteNoStaff) &&
        this.searchCondition.layer_display.visit_no_staff
      ) {
        filterEvents.push(event);
      } else if (
        event.type === EventType.StaffSchedule &&
        this.searchCondition.layer_display.calendar
      ) {
        filterEvents.push(event);
      } else if (event.type === EventType.Holiday) {
        filterEvents.push(event);
      }
    }

    return filterEvents;
  }

  /** カレンダー 選択中の年月 */
  private get FormatDateYM(): string {
    return this.focus.substring(0, 7);
  }

  // 検索条件がデフォルト設定か
  private get IsSearchDefault(): boolean {
    return (
      this.searchCondition.patient_ids.length === 0 &&
      this.searchCondition.group_ids.length === 0 &&
      this.searchCondition.group_operator === 1 &&
      this.searchCondition.staff_ids.length === 0 &&
      this.searchCondition.shifts.length === 0
    );
  }

  async created() {
    // 検索条件取得
    this.collection = COLLECTION_SCHEDULE_SEARCH;
    this.documentId = String(this.loginUser.id);
    const searchCond = (await this.documentGet()) as ScheduleSearchCondition;
    if (searchCond) {
      this.searchCondition = this.deepCopy(searchCond);

      if (
        !this.searchCondition.data ||
        this.searchCondition.data.length === 0
      ) {
        this.searchCondition.data = [
          {
            office_id: this.searchCondition.office_id,
            patient_ids: this.searchCondition.patient_ids, // 検索条件 利用者
            group_ids: this.searchCondition.group_ids, // 検索条件 グループ
            group_operator: this.searchCondition.group_operator, // 検索条件 グループ（AND/OR）
            staff_ids: this.searchCondition.staff_ids, // 検索条件 職員
            shifts: this.searchCondition.shifts // 検索条件 シフト
          }
        ];
      }
    } else if (searchCond === false) {
      this.$openAlert(
        "絞り込み情報が正しく取得できませんでした。画面を更新して再度お試しください。"
      );
      return;
    }

    //事業所IDが0だったら、エラーになるため、ログイン事業所か、先頭にする
    if (this.searchCondition.office_id == 0) {
      this.searchCondition.office_id = this.getDefaultOfficeId();
    }

    await this.fetch(true, true); // 初回読み込み
  }

  /** データ取得 */
  private async fetch(wantMaster: boolean, isInit = false): Promise<void> {
    if (isInit !== true && !this.form.validate()) {
      await this.$openAlert("入力内容に不備があります");
      return;
    }

    let isAllCalendar = false;
    let patientIds = this.searchCondition.patient_ids;
    let staffIds = this.searchCondition.staff_ids;
    let shiftNames = this.searchCondition.shifts;
    let groupIds = this.searchCondition.group_ids;
    let groupOperator = this.searchCondition.group_operator;
    if (this.DisplayTypeStaff) {
      isAllCalendar = true;
      patientIds = this.allPatient;
      staffIds = this.allStaff;
      shiftNames = [];
      groupIds = [];
      groupOperator = 1;
    }

    this.postJsonCheck(
      window.base_url + "/api/schedule/get",
      {
        target_year_month: this.FormatDateYM,
        office_id: this.searchCondition.office_id,
        patient_ids: patientIds,
        staff_ids: staffIds,
        shift_names: shiftNames,
        group_ids: groupIds,
        group_operator: groupOperator,
        want_master: wantMaster,
        is_all_calendar: isAllCalendar,
        valid_staff_ids: this.allStaff
      },
      async res => {
        if (res.data) {
          if (wantMaster) {
            this.staffs = res.data.staffs;
            this.patients = res.data.patients;
            this.offices = res.data.offices;
            this.shifts = res.data.shifts;
            this.groups = res.data.groups;
            this.allPatient = res.data.patient_ids;
            this.allStaff = res.data.staff_ids;
            this.excludeInValidIds();
          }

          this.AllEvents = res.data.events || [];
          this.EventYearmonth = this.FormatDateYM;

          if (this.searchCondition.office_id === 0) {
            if (this.loginUser.office_id > 0) {
              this.searchCondition.office_id = this.loginUser.office_id;
            } else {
              this.searchCondition.office_id = Number(this.offices[0].value);
            }
          }
          this.setSearchCondition();
          this.dispSearch = this.deepCopy(this.searchCondition);
          this.refSearchPanel.resetSearchInput();
        }
      }
    );
  }

  /** 選択事業所を変更 */
  private changeOfficeId() {
    this.refSearchPanel.resetCachedItems();
    this.fetch(true);
  }

  /** 検索条件をFirestoreに保存する */
  private setSearchCondition() {
    if (this.collection !== "") {
      // 選択事業所の検索条件が保存されていない場合は、新しく作成
      const index = this.searchCondition.data.findIndex(
        cond => cond.office_id === this.searchCondition.office_id
      );
      if (index === -1) {
        this.searchCondition.data.push({
          office_id: this.searchCondition.office_id,
          patient_ids: this.searchCondition.patient_ids,
          group_ids: this.searchCondition.group_ids,
          group_operator: this.searchCondition.group_operator,
          staff_ids: this.searchCondition.staff_ids,
          shifts: this.searchCondition.shifts
        });
      } else {
        // 選択事業所の検索条件を保存
        this.searchCondition.data[
          index
        ].patient_ids = this.searchCondition.patient_ids;
        this.searchCondition.data[
          index
        ].group_ids = this.searchCondition.group_ids;
        this.searchCondition.data[
          index
        ].group_operator = this.searchCondition.group_operator;
        this.searchCondition.data[
          index
        ].staff_ids = this.searchCondition.staff_ids;
        this.searchCondition.data[index].shifts = this.searchCondition.shifts;
      }
      this.documentSave(this.searchCondition);
    }
  }

  // 訪問予定登録
  private insertEvent(): void {
    if (this.patients.length === 0) {
      return;
    }
    let patientId = 0;
    if (
      this.searchCondition.patient_ids &&
      this.searchCondition.patient_ids.length > 0
    ) {
      patientId = this.searchCondition.patient_ids[0];
    }
    const d = new Date();
    const date = appDate.dateToStr(d, "yyyy-MM-dd");
    this.calendar.visitPlanEditEventDialog.open(
      0,
      date,
      d.getHours(),
      d.getMinutes(),
      patientId
    );
  }

  // カレンダークリックの処理をする
  private clickCalendar(e: CalendarEvent) {
    let patientId = 0;
    if (
      this.searchCondition.patient_ids &&
      this.searchCondition.patient_ids.length > 0
    ) {
      patientId = this.searchCondition.patient_ids[0];
    }
    this.calendar.visitPlanEditEventDialog.open(
      0,
      e.date,
      e.hour,
      e.minute,
      patientId
    );
  }

  /** 職員別表示モードに切り替える */
  private changeDisplayTypeStaff() {
    this.searchCondition.type = "category";
    this.fetchIfNecessary();
  }

  /** データ更新が必要な場合、再取得 */
  private fetchIfNecessary() {
    if (this.FormatDateYM != this.EventYearmonth) {
      this.fetch(false);
    }
  }

  /** 強制的な再取得後、別カレンダーへの遷移時も、再取得 */
  private fetchReset() {
    this.eventStaffYearmonth = "0000000";
    this.eventYearmonth = "0000000";
    this.fetch(false);
  }

  /** デフォルトの事業所ID */
  private getDefaultOfficeId() {
    if (this.loginUser.office_id) {
      return this.loginUser.office_id;
    }

    //未所属であれば、先頭の事業所ID
    return this.HeadOffice;
  }

  /** 削除済みの利用者・職員を除外する */
  private excludeInValidIds() {
    // 削除済の利用者が選択されていた場合は除外する
    this.searchCondition.patient_ids = this.searchCondition.patient_ids.filter(
      id => {
        return this.patients.findIndex(patient => patient.value == id) !== -1;
      }
    );
    // 削除済の職員が選択されていた場合は除外する
    this.searchCondition.staff_ids = this.searchCondition.staff_ids.filter(
      id => {
        return this.staffs.findIndex(staff => staff.value == id) !== -1;
      }
    );
  }
}
