
















import { Component, Mixins } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";
import AxiosMixin from "@/mixins/axiosMixin";
import { Choice } from "@/types";

@Component
export default class OfficeSelect extends Mixins(UtilMixin, AxiosMixin) {
  private dialog = false;
  private office: Choice = { value: 0, text: "" };
  private officeList: Choice[] = [{ value: 0, text: "" }];
  private url = "";

  public launch(url: string, officeUrl: string) {
    this.url = url;
    const officeId = this.loginUser.parent_office_id;
    if (officeId == 0) {
      // 所属事業所がない場合は連携済み事業所リスト表示
      this.postJsonCheck(window.base_url + officeUrl, {}, res => {
        this.officeList = res.data.office_list;
        if (this.officeList.length > 0) {
          this.office = this.officeList[0];
        }
        this.dialog = true;
      });
    } else {
      this.windowOpen(officeId);
    }
    return;
  }

  private windowOpen(officeId: number) {
    window.open(`${this.url}?office_id=${officeId}`, "_brank");
    this.dialog = false;
  }

  private selected() {
    const officeId = Number(this.office.value);
    if (isNaN(officeId)) {
      console.log(this.office.value, "is not number.");
    } else {
      this.windowOpen(officeId);
    }
  }

  private close() {
    this.dialog = false;
  }
}
