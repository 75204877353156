import { ApplyPatient, ApplyStaff } from "#/model/common";

export const MENU_BUTTON = {
  /** 訪問へ行く */ VISIT: 91,
  /** ホーム */ HOME: 1,
  /** カレンダー */ CALENDAR: 2,
  /** 利用者情報 */ PATIENT: 3,
  /** 看護記録 */ RECORD: 4,
  /** 24時間体制 */ HOUR24: 51,
  /** 訪問予定管理 */ SCHEDULE: 5,
  /** シフト登録 */ SHIFT: 7,
  /** ファイル */ FILE: 9,
  /** ファイル（共有ファイル） */ FILE_SHARE: 90,
  /** ファイル（利用者ファイル） */ FILE_PATIENT: 91,
  /** 掲示板 */ BBS: 10,
  /** レセプト処理 */ RECIEPT: 12,
  /** iBowレセプト */ IBOW_RECIEPT: 17,
  /** iBow治験 */ IBOW_CHIKEN: 22,
  /** 統計 */ STATISTICS: 19,
  /** 帳票 */ REPORT: 20,
  /** ケアプランデータ連携管理 */ CAREPLAN: 23,
  /** 表出力 */ TABLES: 21,
  /** ダッシュボード */ DASHBOARD: 14,
  /** 統計 */ SUB_STATISTICS: 141,
  /** 帳票 */ SUB_REPORT: 142,
  /** ケアプランデータ連携管理 */ SUB_CAREPLAN: 144,
  /** 表出力 */ SUB_TABLES: 143,
  /** マスタメンテ */ MASTER: 13,
  /** 一覧 */ MASTER_LIST: 130,
  /** 事業所マスタ */ MASTER_OFFICE: 131,
  /** 連携機関マスタ */ MASTER_RELATION: 132,
  /** 看護系マスタ */ MASTER_NURSING: 133,
  /** 選択肢マスタ */ MASTER_CHOICE: 134,
  /** データマスタ */ MASTER_DATA: 135,
  /** ログ */ MASTER_LOG: 136,
  /** 訪問記録情報 */ OFFICEINVOICE: 11,
  /** サポート */ CONTACT: 18
};

export interface UnsubmitReport {
  patient_id: number; // 利用者ID
  report_type: number; // 帳票種別 1〜11
  report_id: number; // 帳票ID
  update_staff_id: number; // 更新職員ID
  update_datetime: string; // 更新日時
  target_staff_id: number; // 対象職員ID（訪問者・担当者等）
  target_date: string; // 対象日（訪問日・報告月等）
  update_datetime_wareki: string; // 更新日時和暦
  target_date_wareki: string; // 対象日和暦
  update_staff?: ApplyStaff; // 更新職員
  target_staff?: ApplyStaff; // 対象職員（訪問者・担当者等）
  patient_name: string; // 利用者名(レスポンスにはなく、取得後に代入)
  patient: ApplyPatient; // 利用者
}

export interface InfoItem {
  id: number;
  time_stamp: string;
  content: string;
  url: string;
}

export const DefaultInfoItem = (): InfoItem => ({
  id: 0,
  time_stamp: "",
  content: "",
  url: ""
});

/** 勤務情報 */
export interface Attend {
  is_use: boolean;
  status: number;
  message: string;
  kst_no: string;
  old_ibow_id: number;
}

/** 勤務情報の初期化 */
export const DefaultAttend = (): Attend => ({
  is_use: false,
  status: 0,
  message: "",
  kst_no: "",
  old_ibow_id: 0
});

export interface MenuButton {
  id: number;
  icon: string;
  title: string;
  url: string;
  avater_color: string;
  info_count: string;
  index: number;
  is_second: number;
  is_outer: number;
  is_emphasis: number;
  is_divider: number;
  childs: MenuButton[];
  another_info_count: number;
  get_office_list_url: string;
}

export interface ContentsDisplay {
  hour24: number;
  bbs: number;
  unsubmit: number;
}

export const DefaultContentsDisplay = (): ContentsDisplay => ({
  hour24: 1,
  bbs: 1,
  unsubmit: 1
});

export interface ButtonItems {
  primaryButtonItems: MenuButton[];
  secondaryButtonItems: MenuButton[];
  selectFontSize: number;
  contentsDisplay: ContentsDisplay;
}

/** 未読/未提出件数 */
export interface Count {
  /** 利用者情報の未読件数 */ count_patient_info: number;
  /** 訪問記録の未読件数 */ count_visit_record: number;
  /** 24時間体制の未読件数 */ count_fulltime: number;
  /** 掲示板の未読件数 */ count_topic: number;
  /** お知らせの未読件数 */ count_admin: number;
  /** 未提出件数 */ count_un_submit: number;
}

/** 未読/未提出件数の初期化 */
export const InitCount = (): Count => ({
  count_patient_info: 0,
  count_visit_record: 0,
  count_fulltime: 0,
  count_topic: 0,
  count_admin: 0,
  count_un_submit: 0
});

/** 本日の予定 */
export interface TimeSchedule {
  period: string; // 訪問開始 ~ 終了時間
  title: string; // 予定のタイトル
  patientId: number; // 訪問する患者のid
  is_visit: boolean; // 訪問予定であるか
  is_over: boolean; // 終了時間を過ぎているか
  content: string; // 訪問メモ
  is_cancel: number;
  hide_visit_btn: boolean; // 訪問できるか
}
